.partner-logo {
  width: 8vw;
}

.partner-logo-big {
  width: 13vw;
}

.partner-container {
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  min-height: 10vw;
}

.partners-text {
  font-size: 1.5rem;
  font-weight: bold;
}


.logo-center {
  display: flex;
  justify-content: center;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.slick-slide.slick-active{
  padding: -10px;
}
.dark {
  background-color: rgb(33, 37, 41);
  color: white;
}

.center-box {
  width: 20%;
  background-color: white;
}

.partner-container h2 {
  padding-top: 1vw;
}

.small-box {
  width: 35%;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.small-box {
  flex: 0 0 100%; /* Take full width on small screens */
}

.hide-on-desktop{
  display: contents;
}
.hide-on-mobile{
  display:none;
}

@media (min-width: 768px) { /* Adjust this breakpoint as needed */
  .small-box {
    flex: 0 0 50%; /* Take half width on medium screens and larger */
  }
  .hide-on-desktop{
    display: none;
  }
  .hide-on-mobile{
    display:contents;
  }
}




.line {
  background-color: black;
  width: 100%;
  height: 3px;
}

@media only screen and (max-width: 840px) {
  .partners-text {
    font-size: 1rem;
    font-weight: bold;
  }
  .partner-logo {
    width: 20vw;
  }

  .small-box {
    margin-top: 1rem;
  }

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1; /* This pushes the footer to the bottom */
}