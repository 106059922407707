.previous-years {
  text-align: center;
}

.year-section h2 {
  margin: 20px 0;
  color: #333;
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust the gap between images as needed */
}

.images img {
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Optional: Adds rounded corners to the images */
}

.previous-years-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 100% of the viewport height */
}

.content {
  flex: 1; /* This allows the content to expand and push the Partners component to the bottom */
}
