.form {
  padding-top: 2rem;
}

.obor-container {
  margin-top: 2rem;
}

.button {
  padding: 6px 10px; /* Reduced padding */
  margin: 5px;
  border: 1px solid #1b75bb; /* Thinner border */
  background-color: white;
  color: #1b75bb;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 0.3rem;
  font-size: 12px; /* Smaller font size */
}

.button:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

.button.selected {
  background-color: #1b75bb; /* Blue background when selected */
  color: white;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .button {
      padding: 4px 6px; /* Even smaller padding on smaller screens */
      font-size: 12px; /* Maintain smaller font size on smaller screens */
  }
}
