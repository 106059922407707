.nav-left {
    display: flex;
}

.nav-info {
display: flex;
flex-direction: column;
}

.nav-info-item {
display: flex;
flex-direction: row;
}

.nav-info-item img {
height: 1.5rem;
margin-right: 0.5rem;
}

/* height: 96px; */
/* margin-top: 0.5rem; */
/* margin-bottom: -1.2rem; */
/* margin-top: -8px;
margin-bottom: -8px; */
/* height: 50px; */
  /* aspect-ratio: 16 / 9; */
  /* aspect-ratio: 351 / 76; */
  /* width: 40%;  */


.logo {
  /* height: 25px;  */
  /* width: 130px; */
  width: 110px;
  height: 23px; 
  width: auto; 
  margin-left: 20px;
}

.logo-new {
  height: 40px; 
  width: auto; 
}


@media (min-width: 840px) {
  .logo, .logo-new {
    height: 40px; 
    margin-left: 0px;

  }
}

/* .logo-new {
  height: 70px;
  margin-top: -8px;
  margin-bottom: -8px;
  /* aspect-ratio: 797 / 297; 
} */

.logo-spacer {
  width: 3px;
  background-color: #005a9a;
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar-item-logo {
height: 2rem;
}

.nav-link {
  margin: auto;
}

.btn-main-navbar {
    margin-left: 2rem;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.8rem;
}

.navigation-item {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  color: black;
  text-align: center;
  text-decoration: none;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler:hover {
  background-color: transparent ; 
}

.navbar-toggler{
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px !important;
}

.navigation-dropdown {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  text-align: center;
  text-decoration: none;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.dropdown-toggle {
  white-space: nowrap;
  color: black !important;
}

.navigation-dropdown-item {
  color: black;
}

.nav-item .dropdown-menu {
  background: white;
}

.nav-item .dropdown-item {
  color: #ffffff;
}

.nav-item .dropdown-item:hover {
  background: #f0ad4e;
}

.navigation-brand {
  color: white;
  text-decoration: none;
}

.navigation-button {
  margin-top: auto;
  margin-bottom: auto;
  line-height: 3rem;
  font-weight: bold;
  border-radius: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  background-color: #1b75bb;
  text-decoration: none;
  text-align: center;
}

.navigation-button:hover {
  color: white; 
  background-color: #0b3d59; /* Lighter blue */
  text-decoration: none;
}