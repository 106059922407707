.video-center {
  min-height: 80vh;
  z-index: 0;
}

.video-text-main {
  color: black;
  font-size:large;
}

.video-text {
  color: black;
  font-size: medium;
}

.button-main {
  width: fit-content;
  margin-top: 50px;
}

.text-button-container{
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: auto; /* Remove forced full width */
  min-width: 0; /* Remove forced full width */
}

.atcb-dark {
  --btn-background: #1B75BB;
  --btn-background-hover: #1B75BB;
}

.btn-main {
    width: 60%;
    font-size: 2rem!Important;
    font-variant: all-petite-caps;
    margin: 1rem;
    justify-content: center;
}

@media (min-width: 992px) { 
  .video-center {
    min-height: 80vh;
  }
  
  .video-text-main {
    padding-top: 20px; 
    font-size: 2rem;
    margin-top: 0px;
  }
  
  .video-text {
    margin-top: 20px;
    font-size: 1.3rem;
    margin-bottom: -25px;
  }
  
  .button-main {
    width: fit-content;
  }
  
}

@media (min-width: 300px) { 
  .text-button-container{
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    align-items:center;
    text-align: center;
    width: 100% !important;
    min-width: 0; /* Remove forced full width */
  }

  .video-text {
    margin-bottom: -30px;
  }
}
