.container-logo {
  width: 50%;
  display: flex;
  justify-content: center;
}

.container-column {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 5rem;
}

.container-row-start {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
  padding-bottom: 1rem;
}

.info-title {
  font-size: 1vw;
  color: white;
  margin: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  text-align: left;
}

.info-box {
  width: 28%;
  margin: 1rem;
}

.organisation-logo {
  width: 60%;
}

.text-center-margin {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.padding-bottom-15 {
  padding-bottom: 15rem;
}

.container-center-75 {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 840px) {
  .container-row {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    padding-bottom: 5rem;
  }

  .container-row-start {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column !important;
    padding-bottom: 5rem;
  }

  .container-center-75 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .info-box {
    width: 92%;
    margin: 1rem;
  }

  .info-title {
    font-size: 1.2rem;
    color: white;
    margin: 1rem;
    font-style: oblique;
  }

  .organisation-logo {
    width: 100%;
  }
}

.container-socials {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.horizontal-margin-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.container-icon-title {
  width: 100%;
  background-color: #0363AD;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 0.6rem;  
}

.promote-text {
  font-size: 2rem;
  color: black;
  margin: 1rem;

  .h2 {
    color: black;
  }
}

.title-size-3 {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.text-color-dimmed {
  color: white;
}

.organisation-wide {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container-button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
}

.container-button .button-main {
  margin-top: -1rem;
}

.container-main {
  width: 60%;
}

.info-list-points {
  text-align: left;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #000305;
}

.info-list-points li {
  font-size: 1rem;
}

.info-list-points ul {
  list-style: none;
  /* Remove default bullets */
  padding-left: 1rem;
}

.info-list-points ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #064779;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
  top: 1rem;
}

.info-list {
  padding: 2rem;
  background-color: #00b1e7da;
  margin-left: 1rem;
  margin-right: 1rem;
}

.hover-opacity {
  opacity: 0.5;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
}

.hover-opacity:hover {
  opacity: 1;
}

/* Shine */
.hover-shine {
  position: relative;
}

.hover-shine::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover-shine:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.container_socials {
  width: 75%;
  margin: 0 auto;
}

.container_logos a,
.container_logos div {
  position: relative;
  display: inline-block;
  cursor: pointer;
  /* outline: black 1px solid; */
  width: 75px;
  height: 75px;
}

.container_logos img {
  width: 70%;
  height: 70%;
  margin-inline: auto;
  margin-top: 15%;
  margin-bottom: 15%;
  transition: transform 0.2s ease-in-out;
}

.container_socials img:hover {
  transform: scale(1.1);
}

.tooltip-text {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  /* Start hidden */
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.email-copy.active .tooltip-text {
  opacity: 1;
  /* Show tooltip */
  visibility: visible;
}

.container_info a {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
  /* outline: black 1px solid; */
  width: 150px;
  height: 150px;
}

.container_info img {
  margin-inline: auto;
  transition: transform 0.2s ease-in-out;
}

.tie {
  height: 70%;
  margin-top: 10%;
  margin-bottom: 20%;
}

.container_info,
.container_logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* Ensures they don't break to a new line unless necessary */
  gap: 10px;
  /* Adds spacing between items */
}

@media (max-width: 768px) {

  .container_info,
  .container_logos {
    flex-wrap: nowrap;
    /* Prevents wrapping on smaller screens */
  }

  .organisation-wide {
    width: 100%;
  }

  .container-row-start {
    padding-bottom: 0rem;
  }
}

.button-link-join-us {
  margin-top: 2rem;
  display: inline-block;
  background-color: #1b75bb; /* Example button color */
  color: white;
  padding: 10px 25px;
  border: none;
  border-radius: 0.3rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  font-size: 1.2rem;
}