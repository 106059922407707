.pdf-page {
  display: flex ;
  flex-direction: column;
  width: fit-content !important;
  margin: auto; /* Center the content horizontally */
  padding-top: 12vh;
  height: 100vh; /* Full viewport height */
  justify-content: center !important; /* Center content vertically */
  align-items: center  !important; /* Center content horizontally */
}


.document-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; /* Ensure the PDF document is centered within the container */
}

.nav-button{
  position: relative;
}
.nav-button.previous {
  z-index: 10;
  left:0;
}

.nav-button.next {
  right:0;
}


.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content !important;
}

.button-container {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  padding-top: 0px;
  width: 100%;
}

.button-row {
  display: none;
}

@media (max-width: 899px) {
  .hide-on-mobile {
    display: none;
  }
  .pdf-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100vh;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
  .button-row {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure the button row spans the full width */
    padding: 0 20px; /* Add padding to the sides */
    box-sizing: border-box; /* Ensure padding doesn't affect the total width */
    
  }
  
  .spacer {
    flex-grow: 1; /* Causes the spacer to grow, pushing buttons to the sides */
  }
  
  .nav-button.previous.desktop {
    display: none;  
  }
  
  .nav-button.next.desktop {
    display: none;  

  }
  
  .document-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%; /* Adjust as necessary */
  }
  
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}




